import { Trans, useTranslation } from 'react-i18next'

import Dot from '@/components/Dot'
import Icon from '@/components/Icon'
import useWidget from '@/hooks/useWidget'
import View from '@/layout/View'
import dataFields from '@/lib/dataFields'

import {
  RunCell,
  RunDetail,
  RunHeader,
  RunHeaderCategory,
  RunHeaderContent,
  RunHeaderIcon,
  RunHeaderTitle,
  RunIcon,
  RunItem,
  RunsWrapper
} from './Runs.styles'

function Runs() {
  const {
    params: { skiRunFields, runs }
  } = useWidget()
  const { t } = useTranslation()

  const getCategoryName = (name: string) => {
    const field = dataFields.runCategories.find(o => o.value === name)
    return field ? t(field.name) : '-'
  }

  const getState = (name: string) => {
    const field = dataFields.runStates.find(o => o.value === name)
    return field ? t(field.name) : '-'
  }

  const getSnowTypeName = (name: string) => {
    const field = dataFields.snowTypeOptions.find(o => o.value === name)
    return field ? t(field.name) : '-'
  }

  const getDotType = (status: string) => {
    switch (status) {
      case 'OPEN':
        return 'green'
      case 'IN_PREPARATION':
        return 'orange'
      case 'CLOSED':
        return 'red'
      default:
        return 'default'
    }
  }

  return (
    <View>
      <RunsWrapper>
        {runs.map((run: any) => (
          <RunItem key={run.id}>
            <RunCell>
              <RunHeader>
                <RunHeaderIcon variant={run.category.toLowerCase()}>
                  <Icon name="flag" />
                </RunHeaderIcon>
                <RunHeaderContent>
                  <RunHeaderTitle>
                    {skiRunFields.includes('name') && <h4>{run.name}</h4>}
                    {skiRunFields.includes('state') && <Dot variant={getDotType(run.status)} />}
                  </RunHeaderTitle>
                  {skiRunFields.includes('category') && <RunHeaderCategory>{getCategoryName(run.category)}</RunHeaderCategory>}
                </RunHeaderContent>
              </RunHeader>
            </RunCell>
            {skiRunFields.includes('snowCover') && (
              <RunCell>
                <RunDetail>
                  <Icon name={run.snowType === 'FIRN' ? 'snowcat' : 'snow'} />
                  <div>
                    <span>
                      {t('run.snow')} {getSnowTypeName(run.snowType).toLowerCase()}
                    </span>
                    <strong> {run.averageSnowCoverThickness || 0}cm</strong>
                  </div>
                </RunDetail>
              </RunCell>
            )}
            {skiRunFields.includes('length') && (
              <RunCell>
                <RunDetail>
                  <Icon name="length" />
                  <div>
                    <span>{<Trans i18nKey="run.totalLength">Length</Trans>}</span>
                    <strong> {run.length || 0}m</strong>
                  </div>
                </RunDetail>
              </RunCell>
            )}
            {skiRunFields.includes('elevationDifference') && (
              <RunCell>
                <RunDetail>
                  <Icon name="peak" />
                  <div>
                    <span>{<Trans i18nKey="run.elevationDifference">Elevation difference</Trans>}</span>
                    <strong> {run.elevationDifference || 0}m</strong>
                  </div>
                </RunDetail>
              </RunCell>
            )}
            {skiRunFields.includes('tilt') && (
              <RunCell>
                <RunDetail>
                  <Icon name="run-angle" />
                  <div>
                    <span>{<Trans i18nKey="run.angle">Run Angle</Trans>}</span>
                    <strong> {run.averageTilt || 0}°</strong>
                  </div>
                </RunDetail>
              </RunCell>
            )}
            <RunCell icons={true}>
              {run.artificialLight && (
                <RunIcon>
                  <Icon name="lights" />
                </RunIcon>
              )}
              {run.gastronomicInfrastructure && (
                <RunIcon variant="orange">
                  <Icon name="food" />
                </RunIcon>
              )}
              {run.sportInfrastructure && (
                <RunIcon variant="pink">
                  <Icon name="sport-infrastructure" />
                </RunIcon>
              )}
              {run.recreationalInfrastructure && (
                <RunIcon variant="purple">
                  <Icon name="recreational-infrastrcture" />
                </RunIcon>
              )}
            </RunCell>
          </RunItem>
        ))}
      </RunsWrapper>
    </View>
  )
}

export default Runs
