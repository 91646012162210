import styled, { css } from 'styled-components'

import { IconSquare, Reset } from '@/lib/mixins'

export const LiftItem = styled.div`
  border-radius: 0.5rem;
  background-color: white;
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
`

export const LiftCol = styled.div`
  flex: 0 0 22%;
  max-width: 22%;
  padding-right: 1.5rem;

  &:first-child {
    flex: 0 0 32%;
    max-width: 32%;
  }

  &:not(:first-child) {
    border-right: 1px solid ${props => props.theme.color.line};
  }
`
export const LiftHeader = styled.div`
  display: flex;
  line-height: 1.5;
`
export const LiftHeaderIcon = styled.div`
  ${IconSquare()}
  flex-shrink: 0;
  margin-right: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
  }
`
export const LiftDetails = styled.ul`
  ${Reset('list')}
  font-size: 0.8rem;
`
export const LiftHeaderContent = styled.div`
  flex: 1 1 auto;
  margin-top: 0.75rem;

  ${LiftDetails} {
    margin-left: -2.2rem;
  }

  > h4 {
    margin-bottom: 0;
  }

  > span {
    display: block;
    margin-bottom: 0.75rem;
    font-size: 0.8rem;
    color: ${props => props.theme.color.muted};
    font-weight: 400;
  }
`

export const LiftDetailsItem = styled.li<{ variant?: string }>`
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  white-space: nowrap;
  color: ${props => (props.variant ? props.theme.color[props.variant] : props.theme.color.global)};

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  > span {
  }

  > strong {
    font-weight: 600;
  }

  > svg {
    &:first-child {
      flex: 0 0 1.5rem;
      margin-right: 0.6rem;
      height: 1.2rem;
      width: 1.2rem;
      display: block;
      fill: currentColor;
    }
    &:last-child {
      flex: 0 0 1.5rem;
      margin-left: 0.5rem;
      svg,
      img {
        height: 1.12rem;
        width: 1.12rem;
        display: block;
        fill: ${props => props.theme.color.muted};
      }
    }
  }

  span {
    font-weight: 400;
    margin-right: 0.35em;
    &:after {
      content: ':';
    }
  }

  ${props =>
    !props.variant &&
    css`
      svg:first-child {
        fill: ${props => props.theme.color.primary};
      }
    `}
`

export const LiftStations = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
`
export const LiftStationsItem = styled.div`
  flex: 0 0 50%;
  padding-left: 2rem;
  overflow: hidden;
  > h5 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 1rem;
  }
`
