const keys = {
  NUM_0: 48,
  NUM_1: 49,
  NUM_2: 50,
  NUM_3: 51,
  NUM_4: 52,
  NUM_5: 53,
  NUM_6: 54,
  NUM_7: 55,
  NUM_8: 56,
  NUM_9: 57,
  CH_UP: 427,
  CH_DOWN: 428,
  GUIDE: 458,
  INFO: 457,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  BACK: 461,
  EXIT: 1001,
  RED: 403,
  GREEN: 404,
  YELLOW: 405,
  BLUE: 406,
  STOP: 413,
  PLAY: 415,
  PAUSE: 19,
  REWIND: 412,
  FAST_FORWARD: 417,
  LAST_CH: 711,
  PORTAL: 602,
  ORDER: 623,
  MINUS: 704,
  POWER: 409,
  VOL_UP: 447,
  VOL_DOWN: 448,
  MUTE: 449,
  RECORD: 416,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  RF_BYPASS: 29,
  NEXT_DAY: 425,
  PREV_DAY: 424,
  APPS: 93,
  LINK: 606,
  FORWARD: 167,
  ZOOM: 251,
  SETTINGS: 611,
  NEXT_FAV_CH: 176,
  RES_1: 112,
  RES_2: 113,
  RES_3: 114,
  RES_4: 115,
  RES_5: 116,
  RES_6: 117,
  LOCK: 619,
  SKIP: 620,
  LIST: 1006,
  LIVE: 622,
  ON_DEMAND: 623,
  PINP_MOVE: 624,
  PINP_UP: 625,
  PINP_DOWN: 626,
  MENU: 18,
  AD: 700,
  ALARM: 701,
  AV_MODE: 31,
  SUBTITLE: 460,
  CC: 460,
  DISC_POWER_OFF: 705,
  DISC_POWER_ON: 706,
  DVD: 707,
  EJECT: 414,
  ENERGY_SAVING: 709,
  FAV: 710,
  FLASHBK: 711,
  INPUT: 712,
  MARK: 713,
  NETCAST: 1e3,
  PIP: 715,
  PIP_CH_DOWN: 716,
  PIP_CH_UP: 717,
  PIP_INPUT: 718,
  PIP_SWAP: 719,
  Q_MENU: 1002,
  Q_VIEW: 1007,
  RATIO: 1005,
  SAP: 723,
  SIMPLINK: 724,
  STB: 725,
  T_OPT: 1004,
  TEXT: 459,
  SLEEP_TIMER: 729,
  TV: 730,
  TV_RAD: 731,
  VCR: 732,
  POWER_LOWBATTERY: 733,
  SMART_HOME: 734,
  SCREEN_REMOTE: 735,
  POINTER: 736,
  LG_3D: 737,
  DATA: 738,
  P: 80,
  Q: 81,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  ESC: 27
}

export default keys
