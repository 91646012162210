import styled, { css } from 'styled-components'

import { Cover } from '@/lib/mixins'

export const ViewScroller = styled.div`
  ${Cover()}
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1rem;
  mask-image: linear-gradient(to bottom, transparent, black 1rem, black);
`

export const ViewInner = styled.div``

export const ViewWrapper = styled.div<{ fullheight?: boolean; cover?: boolean }>`
  position: relative;
  padding: 0 ${props => props.theme.layout.outerMargin};
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex: 1 1 auto;
`
