import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useWidget from '@/hooks/useWidget'

import { ViewInner, ViewScroller, ViewWrapper } from './View.styles'

interface ViewProps {
  fullheight?: boolean
  cover?: boolean
  transparent?: boolean
  lockExit?: boolean
  lockBack?: boolean
  prevRouteOnBack?: boolean
  children?: ReactNode
  title?: string
}

function View({ fullheight, cover, lockBack, lockExit, children, title }: ViewProps, forwardedRef: any) {
  const lockBackRef = useRef(lockBack)
  const lockExitRef = useRef(lockExit)
  const scrollRef = useRef(null)

  useImperativeHandle(forwardedRef, () => ({
    scrollDown: () => {
      try {
        const el: any = scrollRef.current
        const scrollTo = el.scrollTop + el.children[0].clientHeight + parseInt(window.getComputedStyle(el.children[0]).marginBottom)

        el.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        })
      } catch (error) {
        console.warn('Error during scroll down', error)
      }
    },
    scrollUp: () => {
      try {
        const el: any = scrollRef.current
        const scrollTo = el.scrollTop - el.children[0].clientHeight - parseInt(window.getComputedStyle(el.children[0]).marginBottom)

        el.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        })
      } catch (error) {
        console.warn('Error during scroll up', error)
      }
    },
    scrollTop: () => {
      try {
        const el: any = scrollRef.current

        el.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } catch (error) {
        console.warn('Error during scroll top', error)
      }
    }
  }))

  useEffect(() => {
    lockExitRef.current = lockExit
  }, [lockExit])

  useEffect(() => {
    lockBackRef.current = lockBack
  }, [lockBack])

  const initKeys = (e: KeyboardEvent) => {
    const code = e.keyCode
    // switch (code) {
    //   case keys.ESC:
    //   case keys.BACK:
    //     if (!lockBackRef.current) {
    //       if (prevRouteOnBack) {
    //         navigate(-1)
    //       } else {
    //         if (!getCurrentFocusKey().includes('NAVIGIATION_ITEM')) setFocus('NAVIGATION')
    //       }
    //     }
    //     break
    //   case keys.Q:
    //   case keys.EXIT:
    //     if (!lockExitRef.current) {
    //       navigate(-1)
    //     }

    //     break
    // }
  }

  useEffect(() => {
    window.addEventListener('keydown', initKeys)

    return () => {
      window.removeEventListener('keydown', initKeys)
    }
  }, [])

  return (
    <ViewWrapper ref={forwardedRef} fullheight={fullheight} cover={cover}>
      <ViewScroller ref={scrollRef}>{children}</ViewScroller>
    </ViewWrapper>
  )
}

export default forwardRef(View)
