import React, { createContext, PropsWithChildren, useReducer } from 'react'

export type WidgetStatus = 'NONE' | 'LOADED' | 'ERROR' | 'LOADING'
export type WidgetTab = 'skiLifts' | 'skiRuns' | 'weather'

export interface WidgetContextState {
  isNavigationOpen: boolean
  status: WidgetStatus
  params: any
  selectedStation: any
  currentTab: WidgetTab
  currentView: any
}
const defaultState: WidgetContextState = {
  isNavigationOpen: false,
  status: 'NONE',
  params: null,
  selectedStation: null,
  currentTab: 'skiLifts',
  currentView: null
}

export type WidgetContextActionTypes =
  | 'SET_PARAMS'
  | 'SET_SELECTED_STATION'
  | 'OPEN_NAVIGATION'
  | 'CLOSE_NAVIGATION'
  | 'SET_CURRENT_TAB'
  | 'SET_STATUS'
  | 'SET_SCROLL_TO'
  | 'SET_CURRENT_VIEW'

export interface WidgetContextAction {
  type: WidgetContextActionTypes
  payload?: any
}

export interface WidgetContextProps {
  state: WidgetContextState
  dispatch: React.Dispatch<WidgetContextAction>
}

export const WidgetContext = createContext<WidgetContextProps>({
  state: defaultState,
  // eslint-disable-next-line
  dispatch: () => {}
})

export const WidgetReducer = (state: WidgetContextState, action: WidgetContextAction) => {
  switch (action.type) {
    case 'SET_PARAMS':
      return {
        ...state,
        params: action.payload
      }
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload
      }
    case 'SET_SELECTED_STATION':
      return {
        ...state,
        selectedStation: action.payload
      }
    case 'OPEN_NAVIGATION':
      return {
        ...state,
        isNavigationOpen: true
      }
    case 'CLOSE_NAVIGATION':
      return {
        ...state,
        isNavigationOpen: false
      }
    case 'SET_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.payload
      }
    case 'SET_CURRENT_TAB':
      return {
        ...state,
        currentTab: action.payload
      }
    default:
      return state
  }
}

export const WidgetProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(WidgetReducer, defaultState)

  return <WidgetContext.Provider value={{ state, dispatch }}>{children}</WidgetContext.Provider>
}
