import styled, { css, keyframes } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

const loadingOne = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const loadingTwo = keyframes`
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
`

export const LoaderObject = styled.div`
  position: relative;
  width: 56px;
  height: 12px;
  display: flex;
  justify-content: space-between;

  > span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.theme.color.primary};

    &:nth-child(1),
    &:nth-child(3) {
      animation: ${loadingOne} 1s infinite;
    }

    &:nth-child(2) {
      animation: ${loadingTwo} 1s infinite;
    }
  }
`
