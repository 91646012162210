import { normalize } from 'polished'
import styled, { createGlobalStyle } from 'styled-components'

import { Cover } from './lib/mixins'
import theme from './lib/theme'

export const AppLoader = styled.div`
  ${Cover()}
  background-color: rgba(255,255,255,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const Style = createGlobalStyle`
  ${normalize()}

  html {
	  font-size: 1.1vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    color: ${theme.color.global};
    font-weight: 300;
    letter-spacing: -0.01em;
    background-color: ${theme.color.body}
  }

  * {
    box-sizing: border-box;
  }
 
  body {
    user-select: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: 0.75em;
    font-weight: 600;
    line-height: 1.1;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 700;

  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;

  }

  h3 {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1em;
  }

  h4 {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1.5em;
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 700;
  }
`

export default Style
