import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import useWidget from '@/hooks/useWidget'

import { useViewScroll } from '../View'
import { TabsButton, TabsWrapper } from './Tabs.styles'

export interface TabsItemProps {
  children: ReactNode
  name: string
  path: string
  active: boolean
  focusable: boolean
}

function TabsItem({ children, name, active, path, focusable }: TabsItemProps) {
  const navigate = useNavigate()
  const { setCurrentTab } = useWidget()
  const { scrollUp, scrollDown } = useViewScroll()
  const onFocus = () => {
    setCurrentTab(name)
    navigate(path, { replace: true })
  }
  const onArrowPress = (e: any) => {
    if (e === 'down') {
      scrollDown()
      return false
    } else if (e === 'up') {
      scrollUp()
      return false
    } else {
      return true
    }
  }
  const { focused, ref, setFocus } = useFocusable({ trackChildren: true, onFocus, focusKey: `TAB_ITEM_${name}`, onArrowPress })

  return (
    <TabsButton ref={ref} focused={focused} active={active} focusable={focusable}>
      <span>{children}</span>
    </TabsButton>
  )
}

function Tabs() {
  const { currentTab, params } = useWidget()
  const { t } = useTranslation()

  const { ref, focusKey, focusSelf, hasFocusedChild } = useFocusable({
    focusable: true,
    trackChildren: true,
    focusKey: 'TABS'
  })

  const tabs = [
    {
      name: 'skiLifts',
      param: 'lifts',
      path: '/',
      label: t('navigation.lifts')
    },
    {
      name: 'skiRuns',
      param: 'runs',
      path: '/runs',
      label: t('navigation.runs')
    },
    {
      name: 'weather',
      param: 'weather',
      path: '/weather',
      label: t('navigation.weather')
    }
  ]

  useEffect(() => {
    focusSelf()
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <TabsWrapper ref={ref}>
        {tabs
          .filter(o => params.menuFields.includes(o.name) && params[o.param])
          .map(item => (
            <TabsItem name={item.name} key={item.name} path={item.path} active={currentTab === item.name} focusable={hasFocusedChild}>
              {item.label}
            </TabsItem>
          ))}
      </TabsWrapper>
    </FocusContext.Provider>
  )
}

export default Tabs
