interface Breakpoints {
  [index: string]: number
}

interface Theme {
  color: {
    [index: string]: string
  }
  font: {
    [index: string]: {
      [index: string]: string
    }
  }
  layout: {
    [index: string]: string
  }
  shadow: {
    [index: string]: string
  }
  variants: string[]
}

export const breakpoints: Breakpoints = {
  xxs: 0,
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1439,
  xxl: 1829
}

const theme: Theme = {
  color: {
    body: '#F4F9FF',
    primary: '#227FDD',
    global: '#000000',
    muted: 'rgba(36,67,109,0.5)',
    inverted: '#ffffff',
    red: '#DD2626',
    green: ' #2aac64',
    orange: ' #ec9744',
    pink: ' #ff7694',
    purple: ' #71608e',
    line: 'rgba(36,67,109,0.15)'
  },
  font: {
    primary: {
      family: 'Lufga, sans-serif',
      regular: 'normal',
      medium: '500',
      bold: '600'
    },
    secondary: {
      family: 'Lufga, sans-serif',
      regular: 'normal',
      medium: '500',
      bold: '600'
    },
    tertiary: {
      family: 'Lufga, sans-serif',
      regular: 'normal',
      medium: '500',
      bold: '600'
    }
  },
  layout: {
    navigationSize: '27vw',
    outerMargin: '3rem'
  },
  shadow: {
    md: '0 0 20px 0 rgba(0,0,0,0.1)'
  },
  variants: ['red', 'primary']
}

export default theme
