import { useEffect, useRef } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Main, Wrapper } from './App.styles'
import Loader from './components/Loader'
import Lifts from './features/Lifts'
import Runs from './features/Runs'
import Weather from './features/Weather'
import useWidget from './hooks/useWidget'
import Background from './layout/Background'
import Header from './layout/Header'
import Navigation from './layout/Navigation'
import Tabs from './layout/Tabs'
import keys from './lib/keys'
import { AppLoader } from './style'

function App() {
  const { fetchWidget, status, setSelectedStation, isNavigationOpen, params, openNavigation, closeNavigation } = useWidget()
  const isNavigationOpenRef = useRef(isNavigationOpen)

  useEffect(() => {
    isNavigationOpenRef.current = isNavigationOpen
  }, [isNavigationOpen])

  const initKeys = (e: KeyboardEvent) => {
    const code = e.keyCode

    switch (code) {
      case keys.BACK:
      case keys.ESC:
        if (!isNavigationOpenRef.current) openNavigation()
        else closeNavigation()
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', initKeys)
    fetchWidget({}).then((data: any) => {
      setSelectedStation(data.availableStations.find((o: any) => o.id === data.skiStationId))
    })

    return () => {
      window.removeEventListener('keydown', initKeys)
    }
  }, [])

  return (
    <>
      {params && (
        <Wrapper>
          <Background disabled={isNavigationOpen} />
          <Navigation />
          <Main disabled={isNavigationOpen}>
            <Header />
            <Tabs />
            <Routes>
              <Route path="/" element={<Lifts />} />
              <Route path="/runs" element={<Runs />} />
              <Route path="/weather" element={<Weather />} />
            </Routes>
          </Main>
        </Wrapper>
      )}
      {status === 'LOADING' && (
        <AppLoader>
          <Loader />
        </AppLoader>
      )}
    </>
  )
}

export default App
