import { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'

export interface IconProps {
  name: string
  namespace?: string
}

const Icon = ({ name, namespace = '' }: IconProps) => {
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    import(`@/assets/icons/${namespace}${name}.svg`).then(res => setIcon(res.default))
  }, [])

  try {
    return icon && <SVG src={icon}></SVG>
  } catch (error) {
    console.error(`[Icon] Error while loading "${namespace}${name}" icon`, error)
    return <div></div>
  }
}

export default Icon
