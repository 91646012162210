import styled, { css } from 'styled-components'

export const DotWrapper = styled.span<{ variant: string }>`
  position: relative;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  vertical-align: middle;
  min-width: 1rem;
  border-radius: 50%;
  vertical-align: middle;
  background-color: ${props => props.theme.color[props.variant]};

  ${props =>
    props.variant === 'default' &&
    css`
      opacity: 0.4;
      background-color: ${props => props.theme.color.muted};
    `};
`
