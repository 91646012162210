import { timingFunctions } from 'polished'
import { css } from 'styled-components'

import theme from './theme'

export const TransitionPrimary = (property = 'all', delay = '0s') => css`
  transition-property: ${property};
  transition-duration: 0.2s;
  transition-delay: ${delay};
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  will-change: ${property};
`

export const TransitionSecondary = (property = 'all', delay = '0s') => css`
  transition-property: ${property};
  transition-duration: 0.75s;
  transition-delay: ${delay};
  transition-timing-function: ${timingFunctions('easeOutExpo')};
  will-change: ${property};
`

export const Cover = (offset = '0') => css`
  position: absolute;
  top: ${offset};
  left: ${offset};
  right: ${offset};
  bottom: ${offset};
  content: ' ';
`

export const ImgCover = () => css`
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const Font = (size = '1rem', weight = 'regular', name = 'primary') => css`
  font-weight: ${theme.font[name][weight]};
  font-size: ${size};
  font-family: ${theme.font[name].family};
`

export const Reset = ($type = 'all') => {
  if ($type === 'link') {
    return css`
      color: inherit;

      &:hover,
      &:focus {
        color: inherit;
      }
    `
  }
  if ($type === 'spacing') {
    return css`
      margin: 0;
      padding: 0;
    `
  }
  if ($type === 'list') {
    return css`
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
      }
    `
  }
  if ($type === 'button') {
    return css`
      color: inherit;
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
    `
  }
  if ($type === 'all') {
    return css`
      color: inherit;
      background-color: transparent;
      border: 0;
      float: none;
      height: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      position: static;
      width: auto;
    `
  }
}

export const HasSvgIcon = (size = '1em') => css`
  svg {
    height: ${size};
    width: ${size};
    display: block;
  }
`

export const LastMb0 = () => css`
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const IconSquare = (variant?: string) => css`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${props => (variant && props.theme.color[variant] ? props.theme.color[variant] : props.theme.color.primary)};

  svg {
    position: relative;
    width: 60%;
    height: 60%;
    display: block;
    fill: white;
  }

  /* &--blue_red,
  &--red_black {
    &:before {
      @include fill-parent;
      transform: rotate(45deg) translate3d(-50%, -50%, 0) scaleX(3);
    }
  }

  &--blue_red {
    background-color: $color--blue;
    &:before {
      background-color: $color--red;
    }
  }

  &--red_black {
    background-color: $color--red;
    &:before {
      background-color: black;
    }
  }  */
`
export const IconOval = (variant?: string) => css`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  fill: white;
  background-color: ${props => (variant && props.theme.color[variant] ? props.theme.color[variant] : props.theme.color.primary)};

  svg {
    position: relative;
    width: 60%;
    height: 60%;
    display: block;
    fill: white;
  }

  /* &--blue_red,
  &--red_black {
    &:before {
      @include fill-parent;
      transform: rotate(45deg) translate3d(-50%, -50%, 0) scaleX(3);
    }
  }

  &--blue_red {
    background-color: $color--blue;
    &:before {
      background-color: $color--red;
    }
  }

  &--red_black {
    background-color: $color--red;
    &:before {
      background-color: black;
    }
  }  */
`
