import moment from 'moment'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import useWidget from '@/hooks/useWidget'
import View from '@/layout/View'
import dataFields from '@/lib/dataFields'

import {
  LiftCol,
  LiftDetails,
  LiftDetailsItem,
  LiftHeader,
  LiftHeaderContent,
  LiftHeaderIcon,
  LiftItem,
  LiftStations,
  LiftStationsItem
} from './Lifts.styles'

function Lifts() {
  const {
    setCurrentView,
    params: { skiLiftFields, lifts }
  } = useWidget()
  const { t } = useTranslation()
  const viewRef = useRef(null)

  const getLiftTypeName = (name: string) => {
    const field = dataFields.liftTypeOptions.find((o: any) => o.value === name)
    return field?.name ? t(field.name) : '-'
  }

  const getLiftTypeIcon = (name: string) => {
    const field = dataFields.liftTypeOptions.find(o => o.value === name)
    return field?.icon || 'gondola'
  }

  const getOpenState = (item: any) => {
    if (item.openHours) {
      const current = moment()
      const from = moment(current.format('YYYY-MM-DD ') + item.openHours.from)
      const to = moment(current.format('YYYY-MM-DD ') + item.openHours.to)

      return current.isBetween(from, to)
    } else {
      return false
    }
  }

  const getOpenHours = (item: any) => {
    if (item.openHours && item.openHours.from && item.openHours.to && item.status !== 'CLOSED')
      return `${item.openHours.from.substring(0, item.openHours.from.length - 3)} - ${item.openHours.to.substring(
        0,
        item.openHours.to.length - 3
      )}`
    else return '-'
  }

  const getCloseHour = (item: any) => {
    if (item.openHours && item.openHours.to) {
      const current = moment()
      const to = moment(current.format('YYYY-MM-DD ') + item.openHours.to)
      return to.format('HH:mm')
    } else {
      return '-'
    }
  }

  const isClosedSoon = (item: any) => {
    if (item.openHours && item.openHours.to) {
      const current = moment()
      const to = moment(current.format('YYYY-MM-DD ') + item.openHours.to)

      return to.hour() - current.hour() <= 2
    } else {
      return '-'
    }
  }

  useEffect(() => {
    setCurrentView(viewRef)
  }, [])

  return (
    <View ref={viewRef}>
      {lifts?.map((item: any) => (
        <LiftItem key={item.id}>
          <LiftCol>
            <LiftHeader>
              <LiftHeaderIcon>
                <Icon name={getLiftTypeIcon(item.liftType)} />
              </LiftHeaderIcon>
              <LiftHeaderContent>
                <h4>{item.name}</h4>
                <span>{getLiftTypeName(item.liftType)}</span>
                {skiLiftFields.includes('status') && (
                  <LiftDetails>
                    {item.status === 'OPEN' && getOpenState(item) && !isClosedSoon(item) && (
                      //- Status - Open
                      <LiftDetailsItem variant="green">
                        <Icon name="clock" />
                        <strong>
                          {t('lift.states.openUntil')} {getCloseHour(item)}
                        </strong>
                      </LiftDetailsItem>
                    )}
                    {item.status === 'OPEN' && getOpenState(item) && isClosedSoon(item) && (
                      //- Status - Open (closed soon)
                      <LiftDetailsItem variant="orange">
                        <Icon name="clock" />
                        <strong>
                          {t('lift.states.closedSoon')} {getCloseHour(item)}
                        </strong>
                      </LiftDetailsItem>
                    )}
                    {item.status === 'OPEN' && !getOpenState(item) && (
                      //- Status - Open (but closed)
                      <LiftDetailsItem variant="muted">
                        <Icon name="clock" />
                        <strong>{getOpenHours(item)}</strong>
                      </LiftDetailsItem>
                    )}
                    {item.status === 'CLOSED' && (
                      //- Status - Closed
                      <LiftDetailsItem variant="red">
                        <Icon name="clock" />
                        <strong>{t('lift.states.closed')}</strong>
                      </LiftDetailsItem>
                    )}
                    {item.status === 'TEMPORARILY_CLOSED' && (
                      //- Status - Temporary Closed
                      <LiftDetailsItem variant="orange">
                        <Icon name="clock" />
                        <strong>{t('lift.states.temporariliClosed')}</strong>
                      </LiftDetailsItem>
                    )}
                  </LiftDetails>
                )}
              </LiftHeaderContent>
            </LiftHeader>
          </LiftCol>
          <LiftCol>
            <LiftDetails>
              {skiLiftFields.includes('length') && (
                <LiftDetailsItem>
                  <Icon name="run-angle-up" />
                  <span>{t('lift.length')}</span>
                  <strong>{item.length ? `${item.length}m` : '-'}</strong>
                </LiftDetailsItem>
              )}
              {skiLiftFields.includes('rideTime') && (
                <LiftDetailsItem>
                  <Icon name="ride-time" />
                  <span>{t('lift.departureDuration')}</span>
                  <strong>{item.rideTime ? `${item.rideTime}min` : '-'}</strong>
                </LiftDetailsItem>
              )}
              {skiLiftFields.includes('lastRunUp') && (
                <LiftDetailsItem>
                  <Icon name="up" />
                  <span>{t('lift.departureDuration')}</span>
                  <strong>{item.lastRunUp ? item.lastRunUp.substring(0, item.lastRunUp.length - 3) : '-'}</strong>
                </LiftDetailsItem>
              )}
            </LiftDetails>
          </LiftCol>
          <LiftStations>
            {item.operatorStations.map((station: any, index: number) => (
              <LiftStationsItem key={index}>
                <h5>{station.name}</h5>
                <LiftDetails>
                  <LiftDetailsItem>
                    <Icon name="peak" />
                    <strong>{station.height ? `${station.height}m` : '-'}</strong>&nbsp;n.p.m.
                  </LiftDetailsItem>
                  {station.parking && (
                    <LiftDetailsItem>
                      <Icon name="parking" />
                      <strong>{t('lift.parkingAvailable')}</strong>
                    </LiftDetailsItem>
                  )}
                </LiftDetails>
              </LiftStationsItem>
            ))}
          </LiftStations>
        </LiftItem>
      ))}
    </View>
  )
}

export default Lifts
