import { motion } from 'framer-motion'

import { BackgroundWrapper } from './Background.styles'

export interface BackgroundProps {
  src?: string
  disabled: boolean
}

function Background({ src, disabled }: BackgroundProps) {
  return (
    <BackgroundWrapper disabled={disabled}>
      <img src="./img/bg-sample.jpg" alt="" />
    </BackgroundWrapper>
  )
}

export default Background
