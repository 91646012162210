import {
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Color,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableChartContext,
  Title
} from 'chart.js'
import hexToRgba from 'hex-to-rgba'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import useWidget from '@/hooks/useWidget'
import { getIcon } from '@/lib/weather'

import { WeatherBox, WeatherTimelineChart, WeatherTimelineDetails, WeatherTimelineDetailsItem } from './Weather.styles'

Chart.register(Title, LineElement, CategoryScale, LinearScale, PointElement)
interface WeatherTimelineProps {
  weatherData: Array<any>
}
interface ChartColor {
  value: number
  color: string
}

const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  // responsiveAnimationDuration: 500,
  layout: {
    padding: 0
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 48,
        font: {
          family: 'Poppins, sans-serif',
          weight: 'bold',
          size: 14
        },
        color: '#232323',
        padding: 10
      },
      grid: {
        color: 'rgba(0,0,0,0.1)'
        // zeroLineColor: 'rgba(0,0,0,0.1)'
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 10,
        font: {
          family: 'Poppins, sans-serif',
          weight: 'bold',
          size: 14
        },
        color: '#232323',
        padding: 10,
        callback: function (value) {
          return value + '°C'
        }
      },
      grid: {
        color: 'rgba(0,0,0,0.1)'
        // zeroLineColor: 'rgba(0,0,0,0.1)'
      }
    }
  },
  plugins: {
    filler: {
      propagate: true
    }
  }
}

const colors: ChartColor[] = [
  {
    value: -10,
    color: '#754FDF'
  },
  {
    value: 5,
    color: '#3985D3'
  },
  {
    value: 15,
    color: '#83BA4F'
  },
  {
    value: 25,
    color: '#C77B42'
  },
  {
    value: 33,
    color: '#C15454'
  }
]

const borderColor = (context: ScriptableChartContext, values: any): Color => {
  const chartArea = context.chart.chartArea
  const min = Math.min(...values)
  const max = Math.max(...values)
  const diff = max - min
  const step = diff / (colors.length - 1)

  if (!chartArea) {
    return '#fffff'
  }

  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  let gradient = null
  let width = null
  let height = null

  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    const ctx = context.chart.ctx
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    const colorRefOne = colors.find(o => o.value > min)
    gradient.addColorStop(0, colorRefOne ? colorRefOne.color : colors[0].color)
    for (let i = 1; i <= colors.length - 2; i++) {
      const colorRefTwo = colors.find(o => o.value > min + i * step)
      gradient.addColorStop(i * (1 / colors.length), colorRefTwo ? colorRefTwo.color : colors[colors.length - 1].color)
    }
    const colorRefThree = colors.find(o => o.value > max)
    gradient.addColorStop(1, colorRefThree ? colorRefThree.color : colors[colors.length - 1].color)
  }

  return gradient
}

const gradientColor = (context: ScriptableChartContext, values: any): Color => {
  const chartArea = context.chart.chartArea
  const min = Math.min(...values)
  const max = Math.max(...values)
  const diff = max - min
  const step = diff / (colors.length - 1)

  if (!chartArea) {
    // This case happens on initial chart load
    return '#ffffff'
  }

  let gradient = null

  if (gradient === null) {
    const ctx = context.chart.ctx
    gradient = ctx.createLinearGradient(0, 0, 0, 400)
    const colorMinRef = colors.find(o => o.value > min)
    let color = colorMinRef ? colorMinRef.color : colors[0].color

    gradient.addColorStop(0, hexToRgba(color, 0.1))
    for (let i = 1; i <= colors.length - 2; i++) {
      const colorRef = colors.find(o => o.value > min + i * step)
      color = colorRef ? colorRef.color : colors[colors.length - 1].color
      gradient.addColorStop(i * (1 / colors.length), hexToRgba(color, 0.1))
    }
    const colorMaxRef = colors.find(o => o.value > max)
    color = colorMaxRef ? colorMaxRef.color : colors[colors.length - 1].color
    gradient.addColorStop(1, hexToRgba(color))
  }

  return gradient
}

function WeatherTimeline({ weatherData }: WeatherTimelineProps) {
  const {
    params: { weather }
  } = useWidget()
  const { t } = useTranslation()

  const temperatures = useMemo(() => {
    return weatherData.map((o: any) => o.temperature)
  }, [weather])
  const perceivedTemperatures = useMemo(() => {
    return weatherData.map((o: any) => o.perceivedTemperature)
  }, [weather])
  const futureDetails = useMemo(() => {
    return weatherData.filter((o: any, index: number) => index % 2 === 0)
  }, [weather])

  const data: ChartData<'line'> = {
    labels: weatherData.map((o: any) => o.hour),
    datasets: [
      {
        label: 'Temperature',
        borderColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        borderCapStyle: 'round',
        // borderJoinStyle: (context: ScriptableChartContext) => borderColor(context, temperatures),
        borderWidth: 2,
        fill: 1,
        backgroundColor: (context: ScriptableChartContext) => gradientColor(context, temperatures),
        // @ts-ignore
        lineTension: 0.5,
        pointBackgroundColor: (context: ScriptableChartContext) => gradientColor(context, temperatures),
        pointHoverBackgroundColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        pointHoverBorderColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        pointBorderColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        pointRadius: 2,
        data: temperatures
      },
      {
        label: 'Temperature',
        borderColor: (context: ScriptableChartContext) => borderColor(context, perceivedTemperatures),
        borderCapStyle: 'round',
        // borderJoinStyle: (context: ScriptableChartContext) => borderColor(context, perceivedTemperatures),
        borderWidth: 2,
        borderDash: [0, 5],
        fill: false,
        // @ts-ignore
        lineTension: 0.5,
        pointBackgroundColor: (context: ScriptableChartContext) => borderColor(context, perceivedTemperatures),
        pointHoverBackgroundColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        pointHoverBorderColor: (context: ScriptableChartContext) => borderColor(context, temperatures),
        pointBorderColor: (context: ScriptableChartContext) => borderColor(context, perceivedTemperatures),
        pointRadius: 2,
        data: perceivedTemperatures
      }
    ]
  }

  const getHour = (hour: string) => {
    return Number(hour.split(':')[0])
  }

  return (
    <WeatherBox>
      <WeatherTimelineDetails>
        {futureDetails.map((time: any, index: number) => (
          <WeatherTimelineDetailsItem key={index}>
            <Icon
              name={getIcon(time.weatherCode)}
              namespace={`weather/${
                getHour(time.hour) < getHour(weather.sunset) && getHour(time.hour) > getHour(weather.sunrise) ? 'day' : 'night'
              }/`}
            ></Icon>
          </WeatherTimelineDetailsItem>
        ))}
      </WeatherTimelineDetails>
      <WeatherTimelineChart>
        <Line options={options} data={data} />
      </WeatherTimelineChart>
    </WeatherBox>
  )
}

export default WeatherTimeline
