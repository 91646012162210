import useWidget from '@/hooks/useWidget'
import View from '@/layout/View'

import WeatherCurrent from './WeatherCurrent'
import WeatherTimeline from './WeatherTimeline'

function Weather() {
  const {
    params: { weather }
  } = useWidget()

  return (
    <View>
      {weather && (
        <>
          <WeatherCurrent weatherData={weather.current || weather} />
          <WeatherTimeline weatherData={weather.futureWeather || weather.timeline} />
        </>
      )}
    </View>
  )
}

export default Weather
