import { DotWrapper } from './Dot.styles'

interface DotProps {
  variant: string
}

function Dot({ variant = 'default' }: DotProps) {
  return <DotWrapper variant={variant}></DotWrapper>
}

export default Dot
