export default {
  trafficOptions: [
    {
      name: 'none',
      value: 'NONE'
    },
    {
      name: 'scarce',
      value: 'SCARCE'
    },
    {
      name: 'low',
      value: 'LOW'
    },
    {
      name: 'moderate',
      value: 'MODERATE'
    },
    {
      name: 'high',
      value: 'HIGH'
    },
    {
      name: 'veryHigh',
      value: 'HIGH'
    }
  ],
  snowTypeOptions: [
    {
      value: 'FAKE',
      name: 'snowTypes.fake'
    },
    {
      value: 'FLUFFY',
      name: 'snowTypes.fluffy'
    },
    {
      value: 'GYPSUM',
      name: 'snowTypes.gypsum'
    },
    {
      value: 'COVERING_OF_ICE',
      name: 'snowTypes.coveringOfIce'
    },
    {
      value: 'ICE',
      name: 'snowTypes.ice'
    },
    {
      value: 'WET',
      name: 'snowTypes.wet'
    },
    {
      value: 'GRANULAR',
      name: 'snowTypes.granular'
    },
    {
      value: 'FIRN',
      name: 'snowTypes.firn'
    }
  ],
  liftTypeOptions: [
    {
      value: 'CHAIRLIFT',
      name: 'lift.types.chairlift',
      icon: 'chairlift'
    },
    {
      value: 'CABLEWAY',
      name: 'lift.types.cableway',
      icon: 'gondola'
    },
    {
      value: 'GONDOLA',
      name: 'lift.types.gondola',
      icon: 'gondola'
    },
    {
      value: 'FURNICULAR_RAILWAY',
      name: 'lift.types.furnicuralRailway',
      icon: 'gondola'
    },
    {
      value: 'T_BAR_LIFT',
      name: 'lift.types.tbarlift',
      icon: 'tbarlift'
    },
    {
      value: 'PLATTER_LIFT',
      name: 'lift.types.platterlift',
      icon: 'platterlift'
    },
    {
      value: 'BASKET_LIFT',
      name: 'lift.types.gondola',
      icon: 'gondola'
    },
    {
      value: 'MOVING_WALKWAY',
      name: 'lift.types.moving-walkway',
      icon: 'moving-walkway'
    },
    {
      value: 'UNSUPPORTED_LIFT',
      name: 'lift.types.unsupportedlift',
      icon: 'unsupportedlift'
    }
  ],
  runCategories: [
    {
      name: 'run.categories.blue',
      value: 'BLUE'
    },
    {
      name: 'run.categories.blueRed',
      value: 'BLUE_RED'
    },
    {
      name: 'run.categories.red',
      value: 'RED'
    },
    {
      name: 'run.categories.redBlack',
      value: 'RED_BLACK'
    },
    {
      name: 'run.categories.black',
      value: 'BLACK'
    }
  ],
  runStates: [
    {
      name: 'run.states.open',
      value: 'OPEN',
      status: 'OPEN'
    },
    {
      name: 'run.states.inPreparation',
      value: 'IN_PREPARATION',
      status: 'IN_PREPARATION'
    },
    {
      name: 'run.states.closed',
      value: 'CLOSED',
      status: 'CLOSED'
    }
  ]
}
