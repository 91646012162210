import styled, { css } from 'styled-components'

import { Reset, TransitionSecondary } from '@/lib/mixins'

import { Cover, TransitionPrimary } from './../../lib/mixins'

export const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.layout.outerMargin};
  margin-bottom: 0.5rem;
  flex-shrink: 0;
`

export const TabsButton = styled.button<{ focused: boolean; active: boolean; focusable: boolean }>`
  position: relative;
  ${Reset('button')}
  flex: 1 0 0;
  margin: 0 0.5rem;
  padding: 0.85rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.color.muted};
  ${TransitionPrimary('color')}

  &:before {
    ${Cover()}
    border-radius: 0.5rem;
    background: ${props => props.theme.color.primary};
    opacity: 0;
    transform: scale(1.05);
    ${TransitionPrimary('transform, opacity')}
  }

  span {
    position: relative;
  }

  ${props =>
    props.focusable &&
    css`
      color: ${props => props.theme.color.global}; ;
    `};

  ${props =>
    props.focused &&
    css`
      color: white;
      &:before {
        opacity: 1;
        transform: scale(1);
      }
    `};
`
