import styled, { css } from 'styled-components'

import { IconOval, IconSquare } from '@/lib/mixins'

export const WeatherBox = styled.div`
  border-radius: 0.5rem;
  background-color: white;
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`

export const WeatherCurrentTitle = styled.h3`
  flex: 0 0 100%;
  margin-bottom: 0.75rem;
`
export const WeatherCurrentMain = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 38%;

  > svg {
    flex: 0 0 4rem;
    margin-right: 1.25rem;
    margin-left: 0.5rem;
    height: auto;
  }
`
export const WeatherCurrentMainContent = styled.div`
  flex: 1 1 auto;
`
export const WeatherCurrentMainTemperature = styled.div`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.2;

  sup {
    top: -0.5em;
    margin-left: -0.2em;
    font-size: 60%;
  }
`
export const WeatherCurrentMainCaption = styled.div`
  line-height: 1.2;
  font-size: 0.85rem;
  span {
    &:after {
      content: ':';
      margin-right: 0.5em;
    }
  }
  strong {
    font-weight: 600;

    sup {
      top: -0.5em;
      margin-left: 0.2em;
      font-size: 60%;
    }
  }
`
export const WeatherCurrentDetails = styled.div`
  font-size: 0.85rem;
  display: flex;
  flex-wrap: wrap;
  padding-right: 1rem;
  flex: 0 0 62%;
`
export const WeatherCurrentDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 0.1rem 0;
  flex: 0 0 50%;

  svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  span {
    &:after {
      content: ':';
      margin-right: 0.5em;
    }
  }
  strong {
    font-weight: 600;
  }
`
export const WeatherCurrentDetailContent = styled.div``

export const WeatherTimelineChart = styled.div`
  height: 10rem;
  width: 100%;
  canvas {
    height: 100%;
    width: 100%;
    display: block;
  }
`

export const WeatherTimelineDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.35rem 0 1.3rem;
  width: 100%;
`

export const WeatherTimelineDetailsItem = styled.div`
  svg {
    max-height: 2.25rem;
  }
`
