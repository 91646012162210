import styled, { css } from 'styled-components'

import { IconOval, IconSquare } from '@/lib/mixins'

export const RunsWrapper = styled.div`
  display: table;
  table-layout: fixed;
  overflow-wrap: break-word;
  border-spacing: 0 0.5rem;
  width: 100%;
  margin-top: -0.5rem;
`

export const RunItem = styled.div`
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  display: table-row;
  width: 100%;
`

export const RunHeader = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.4;
`

export const RunHeaderContent = styled.div`
  padding-top: 0.1rem;
`

export const RunHeaderTitle = styled.h4`
  margin-bottom: 0;
  display: flex;
  align-items: center;

  h4 {
    margin-bottom: 0;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }
`

export const RunHeaderCategory = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${props => props.theme.color.muted};
  font-weight: 400;
`

export const RunHeaderIcon = styled.div<{ variant?: string }>`
  ${props => IconSquare(props.variant)}
  flex-shrink: 0;
  margin-right: 1rem;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
  }
`

export const RunCell = styled.div<{ icons?: boolean }>`
  display: table-cell;
  padding: 1.5rem 1rem;
  background-color: white;

  ${props =>
    props.icons &&
    css`
      white-space: nowrap;
    `};

  &:first-child {
    padding-left: 1.5rem;
    width: 16rem;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:last-child {
    padding-right: 1.5rem;
    border-radius: 00.5rem 0.5rem 0;
  }
`

export const RunDetail = styled.div<{ muted?: boolean }>`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
    width: 1.15rem;
    flex-shrink: 0;
    height: 1.15rem;
    fill: ${props => props.theme.color.primary};
  }

  > div {
    line-height: 1.3;
    > span {
      display: block;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.1;
      strong {
        font-weight: 400;
      }
    }

    > strong {
      font-size: 0.9rem;
      font-weight: 700;
    }
  }
`

export const RunIcon = styled.span<{ variant?: string }>`
  ${props => IconOval(props.variant)}
  &:not(:first-child) {
    margin-left: 0.35rem;
  }
`
