import { useCallback } from 'react'

import useWidget from '@/hooks/useWidget'

export function useViewScroll(scroller?: any) {
  const { currentView } = useWidget()
  const handleFocus = useCallback(({ y }: { y: number }) => {
    if (scroller?.current) {
      const el = scroller.current
      let yTarget = y

      if (y <= window.innerHeight * 0.75) {
        yTarget = 0
      } else {
        yTarget = y * 0.9
      }

      el.scrollTo({
        top: yTarget,
        behavior: 'smooth'
      })
    }
  }, [])

  const scrollDown = () => {
    currentView?.current?.scrollDown()
  }

  const scrollUp = () => {
    currentView?.current?.scrollUp()
  }

  const scrollTop = () => {
    currentView?.current?.scrollTop()
  }

  return {
    scrollTop,
    scrollDown,
    scrollUp,
    handleFocus
  }
}
