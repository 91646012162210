import styled, { css } from 'styled-components'

import { Reset } from '@lib/mixins'

import { Cover } from '@/lib/mixins'

import { TransitionPrimary, TransitionSecondary } from './../../lib/mixins'

export const NavigationList = styled.div`
  height: 100%;
  width: calc(100% + 2rem);
  flex: 1 1 auto;
  overflow: auto;
  padding-top: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
  mask-image: linear-gradient(to bottom, transparent, black 2rem);
`

export const NavigationLogo = styled.div`
  position: relative;
  width: 11rem;
  height: 6rem;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`
export const NavigationItemWrapper = styled.div`
  padding: 0.5rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.color.line};
  }
`

export const NavigationBackdrop = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vw;
  width: 100vw;
  pointer-events: none;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.6) 0%, #ffffff 80%);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  ${TransitionSecondary('opacity, visibility')}

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`
export const NavigationLink = styled.button<{ key?: number; focused?: boolean; extended?: boolean; active?: boolean; focusKey?: string }>`
  ${Reset('button')}
  text-align: left;
  position: relative;
  padding: 1.5rem;
  ${TransitionSecondary('all')}
  width: 100%;
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
  color: ${props => props.theme.color.muted};

  &:before {
    ${Cover()}
    opacity: 0;
    border-radius: 0.75rem;
    transform: scale(0.9);
    ${TransitionSecondary('transform, opacity')}
  }

  span {
    position: relative;
  }
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  ${props =>
    props.focused &&
    css`
      color: ${props => props.theme.color.global};
      &:before {
        transform: scale(1);
        opacity: 1;
        background: transparent;
        border: 3px solid ${props => props.theme.color.primary};
      }
    `};

  ${props =>
    props.extended &&
    props.active &&
    css`
      color: ${props => props.theme.color.inverted};
      &:before {
        opacity: 1;
        transform: scale(1);
        background-color: ${props => props.theme.color.primary};
      }
    `};

  ${props =>
    !props.extended &&
    props.active &&
    css`
      color: ${props => props.theme.color.primary};
      &:before {
        opacity: 0.1;
        transform: scale(1);
      }
    `};
`

export const NavigationWrapper = styled.div<{ extended: boolean; focused: boolean }>`
  position: relative;
  height: 100%;
  z-index: 100;
  padding: 3rem ${props => props.theme.layout.outerMargin} 0;
  ${TransitionSecondary(' transform')}
  flex: 0 0 ${props => props.theme.layout.navigationSize};
  display: flex;
  flex-direction: column;
  z-index: 50;

  ${props => !props.extended && css``};

  ${props =>
    props.extended &&
    css`
      transform: translateX(1rem);
    `};
`
