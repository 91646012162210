import styled from 'styled-components'

export const BadgeWrapper = styled.div<{ variant: string }>`
  position: relative;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  padding: 0.35rem 0.8rem;
  border-radius: 2rem;
  color: ${props => props.theme.color.inverted};
  background-color: ${props => props.theme.color[props.variant]}; ;
`
