import { ReactNode } from 'react'

import { BadgeWrapper } from './Badge.styles'

interface BadgeProps {
  children: ReactNode
  variant: string
}

function Badge({ children, variant }: BadgeProps) {
  return <BadgeWrapper variant={variant}>{children}</BadgeWrapper>
}

export default Badge
