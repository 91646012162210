import styled, { css } from 'styled-components'

import { Cover, TransitionSecondary } from './../../lib/mixins'

export const BackgroundWrapper = styled.div<{ disabled: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 65vw;
  height: 60vh;
  opacity: 0.3;
  /* mask-image: linear-gradient(to bottom, black 75%, transparent); */
  z-index: -1;
  ${TransitionSecondary('transform')}

  ${props =>
    props.disabled &&
    css`
      transform: scale(1.1);
    `};

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after,
  &:before {
    ${Cover()}
  }

  &:before {
    z-index: 2;
    background-image: linear-gradient(to right, ${props => props.theme.color.body}, transparent);
  }

  &:after {
    z-index: 2;
    background-image: linear-gradient(to top, ${props => props.theme.color.body}, transparent);
  }
`
