const icons = {
  cloudy: [119],
  fog: [248, 143, 260],
  freezingrain: [377, 374, 350, 314, 311, 284, 281, 185],
  heavyrain: [308, 305],
  heavyshower: [356],
  lightning: [299, 296],
  lightrain: [176, 293],
  lightshower: [266, 263, 359],
  lightsnow: [227, 179],
  mostlycloudy: [122],
  partlycloudy: [116],
  rain: [21, 51, 52, 53, 58, 59, 60, 62, 63],
  shower: [353],
  snow: [395, 392, 338, 335, 332, 329, 230],
  snowrain: [320, 317],
  snowshower: [368, 323, 326, 371],
  snowrainshower: [365, 362, 182],
  sunny: [113],
  thunderstorm: [389, 386, 200],
  wind: [18, 19]
}

export function getIcon(code) {
  let result
  for (const icon in icons) {
    for (let i = 0; i < icons[icon].length; i++) {
      if (code === icons[icon][i]) {
        result = icon
      }
    }
  }
  return result || 'unknow'
}

const descriptions = {
  cloudy: [-8, 7, 8],
  fog: [-4, 10, 11, 12, 28, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
  freezingRain: [-5, 24, 56, 66, 67],
  heavyRain: [-6, -2, -1, 54, 55, 64, 65],
  heavyShower: [],
  lightning: [13],
  lightRain: [20, 50, 61],
  lightShower: [14, 15, 16, 25],
  lightSnow: [26, 70, 71],
  mostlyCloudy: [4, 5, 6],
  partlyCloudy: [1, 2, 3],
  rain: [21, 51, 52, 53, 58, 59, 60, 62, 63],
  shower: [80, 81],
  snow: [338, 335, 332, 329],
  snowAndRain: [23, 27, 68, 69, 82, 83],
  snowShower: [323, 326],
  snowShowerAndRain: [3],
  clear: [0],
  thunderstorm: [17, 29, 91, 92, 93, 94, 95, 96, 97, 98, 99],
  unknown: [9, 30, 31, 32, 33, 34, 35],
  windy: [18, 19]
}

export function setDescription(code) {
  for (const description in descriptions) {
    for (let i = 0; i < descriptions[description].length; i++) {
      if (code === descriptions[description][i]) {
        return description
      }
    }
  }
}

const windValue = {
  0: '0deg',
  1: '5deg',
  2: '10deg',
  3: '15deg',
  4: '20deg',
  5: '25deg',
  6: '30deg',
  7: '35deg',
  8: '40deg',
  9: '45deg',
  10: '50deg',
  11: '55deg',
  12: '60deg',
  13: '65deg',
  14: '70deg',
  15: '75deg',
  16: '80deg',
  17: '85deg',
  18: '90deg',
  19: '95deg',
  20: '100deg',
  21: '105deg',
  22: '110deg',
  23: '115deg',
  24: '120deg',
  25: '125deg',
  26: '130deg',
  27: '135deg',
  28: '140deg',
  29: '145deg',
  30: '150deg',
  31: '155deg',
  32: '160deg',
  33: '165deg',
  34: '170deg',
  35: '175deg',
  36: '180deg'
}

export function getWindStrengthValue(windSpeed) {
  let result
  for (const [key, value] of Object.entries(windValue)) {
    if (windSpeed === key) {
      result = value
    }
  }
  return result
}

const windDirectionsValue = {
  N: '0deg',
  NNE: '22.5deg',
  NE: '45deg',
  ENE: '67.5deg',
  E: '90deg',
  ESE: '112,5deg',
  SE: '135deg',
  SSE: '157.5deg',
  S: '180deg',
  SSW: '202.5deg',
  SW: '225deg',
  WSW: '247.5deg',
  W: '270deg',
  WNW: '292.5deg',
  NW: '315deg',
  NNW: '337.5deg'
}

export function getWindDirectionValue(direction) {
  let result
  for (const [key, value] of Object.entries(windDirectionsValue)) {
    if (direction === key) {
      result = value
    }
  }
  return result
}
