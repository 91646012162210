import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/pl'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import '@assets/fonts/fonts.css'
import theme from '@lib/theme'
import { init } from '@noriginmedia/norigin-spatial-navigation'

import App from './App'
import { WidgetProvider } from './context/WidgetContext'
// import DeviceSocketProvider from './context/DeviceSocketContext'
import './lib/i18n'
import reportWebVitals from './reportWebVitals'
import Style from './style'

init({ visualDebug: false })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ThemeProvider theme={theme}>
    <Style />
    <WidgetProvider>
      <HashRouter basename="/">
        <App />
      </HashRouter>
    </WidgetProvider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
